import { Button, Result } from "antd";
import { useRouter } from "next/router";
import React from "react";

const ErrorComponent = (props) => {
  const error = props.error;
  console.log(error, "error");
  const router = useRouter();
  return (
    <Result
      status={error?.status == "403" ? "403" : "error"}
      title={error?.status == "403" ? "Forbidden" : "An unexpected error occurred."}
      subTitle={
        error?.status == "403"
          ? "You do not have the required permissions to access this page."
          : "Try again or notify the website administrators if issues persist."
      }
      extra={
        error?.status != 403 && (
          <Button type="primary" onClick={() => router.reload()}>
            Refresh Page 
          </Button>
        )
      }
    />
  );
};

export default ErrorComponent;
