import { Layout, Menu, Dropdown, Space, Select, Typography, Button, Row } from "antd";
import { Spinner } from "../components/common";
import React from "react";
import {
  DesktopOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  LogoutOutlined,
  FileTextOutlined ,
  UserOutlined,
  DownOutlined
} from "@ant-design/icons";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import fetchJson from "@/lib/fetcher";
import useUser from "@/lib/useUser";
import { FaFileInvoice, FaFileContract, FaSearchDollar } from "react-icons/fa";
import { RiContactsLine } from "react-icons/ri";
import { SiOpenaccess } from "react-icons/si";
import { anyLocationsHaveViewAccessPermissions } from "@/utils/permissions";
const { Header, Content, Footer, Sider } = Layout;
import Image from "next/image";
import Link from "next/dist/client/link";
import netsuiteLib from "@/lib/netsuitelib";
import userlib from "@/lib/userlib";

const { SubMenu } = Menu;
const allLinks = [
  {
    href: "/dashboard",
    title: "Dashboard",
    icon: <DesktopOutlined />,
  },
  {
    href: "/dashboard/cases",
    title: "Cases",
    icon: <SolutionOutlined />,
    category: "case",
    mode: "view",
  },
  {
    href: "/dashboard/contacts",
    title: "Contacts",
    icon: <RiContactsLine />,
    category: "contact",
    mode: "view",
  },
  {
    href: "/dashboard/invoices",
    title: "Invoices",
    icon: <FaSearchDollar />,
    category: "invoice",
    mode: "view",
  },
  {
    title: "Contracts",
    href: "/dashboard/contracts",
    icon: <FaFileContract />,
    category: "contract",
    mode: "view",
  },
  // {
  //   title: "Install / Deinstall",
  //   href: "/dashboard/install_deinstall",
  //   icon: <VscDebugDisconnect />,
  //   category: "installdeinstall",
  //   mode: "view",
  // },
  // {
  //   // href: "/dashboard/inventory",
  //   title: "Inventory",
  //   icon: <HddOutlined />,
  //   href: "/dashboard/inventory/1",
  //   category: "inventory",
  //   mode: "view",
  //   subLinks: [
  //     {
  //       title: "All",
  //       href: "/dashboard/inventory",
  //       icon: <FileOutlined />,
  //     },
  //     {
  //       title: "Cabinets",
  //       href: "/dashboard/inventory/cabinets",
  //       icon: <FileOutlined />,
  //     },
  //     {
  //       title: "Cross Connects",
  //       href: "/dashboard/inventory/crossconnects",
  //       icon: <FileOutlined />,
  //     },
  //     {
  //       title: "Power Circuits",
  //       href: "/dashboard/inventory/power",
  //       icon: <FileOutlined />,
  //     },
  //   ],
  // },
  {
    title: 'Site Access',
    icon: <SiOpenaccess />,
    href: '/dashboard/accesslog'
  },
  // {
  //   title: "Usage Reports",
  //   icon: <LineChartOutlined />,
  //   href: "/dashboard/reports",
  //   category: "report",
  //   mode: "view",
  //   subLinks: [
  //     {
  //       title: "Network Bandwidth",
  //       href: "/dashboard/reports/bandwidth",
  //     },
  //     {
  //       title: "Power Usage",
  //       href: "/dashboard/reports/power",
  //     },
  //   ],
  // },
  {
    title: "Compliance",
    icon: <FileTextOutlined />,
    href: "/dashboard/compliance",
    category: "compliance",
    mode: "view"
  },
  {
    title: "Guides & References",
    icon: <FaFileInvoice />,
    href: "/dashboard/documents",
  }
];

const Loading = () => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => url !== router.asPath && setLoading(true);
    const handleComplete = (url) => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });
  return loading && <Spinner />;
};


const MainLayout = (props) => {
  const router = useRouter();
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed((prev) => !prev);
  };
  const logout = async () => {
    console.log('logout call')
    try {
      const logout = await fetchJson("/api/auth/logout", {
        method: "POST",
      });
      window.location.href = '/login'
    } catch(e) {
      console.log(e, 'ERROR IN LOGOUT')
    }
  };
  const { user, mutateUser } = useUser({
    redirectTo: "/dashboard",
    redirectIfFound: false,
  });
  console.log(user , 'user')

  const canLoadPortalUsers = userlib.canLoadPortalUsers(user);

  const [thePortalUsers, setPortalUsers] = useState(null);
  // netsuitelib call to user restlet
  async function loadPortalUsers() {
    const response = await netsuiteLib.get(process.env.NEXT_PUBLIC_PORTAL_USERS_SCRIPT_ID, process.env.NEXT_PUBLIC_PORTAL_USERS_DEPLOYMENT_ID);
    console.log("NETSUITE RESPONSE", response);
    let thePortalUsers = response?.results;
    console.log(thePortalUsers, "thePortalUsers BEFORE we filter");

    // Filter portalUsers
    const filteredPortalUsers = thePortalUsers.filter(user => {
      // Check if userName is not an empty string
      // if (user.userName.trim() !== '') {
        // Check if user has an array of customers
        if (user.customers && user.customers.length > 0) {
          // Check if any customer has an empty locations array and isMasterAdmin is false
          const isValidUser = user.customers.some(customer => {
            return customer.locations.length > 0 || customer.isMasterAdmin === true;
          });
          return isValidUser;
        }
      // }
      return false;
    });
    console.log(filteredPortalUsers, "filteredPortalUsers AFTER we filter");
    // thePortalUsers = thePortalUsers.filter((item) => (item.locations.length != 0 || item.isMasterAdmin === true));
    // Sort the portal users by user name
    filteredPortalUsers.sort((a, b) => a.userName.localeCompare(b.userName));
    return filteredPortalUsers;
  }

  useEffect(() => {
    if (canLoadPortalUsers) {
      loadPortalUsers().then((data) => {
        setPortalUsers(data);
      });
      console.log(thePortalUsers, "thePortalUsers");
    }
  }, [canLoadPortalUsers]);

  const permissionLinks = allLinks.filter((links) => {
    if (!links.category) {
      return true;
    } else if (userlib.isMasterAdmin(user)){
      return true;
    } else {
      return anyLocationsHaveViewAccessPermissions(user, links.category, links.mode);
    }
  });

  const onAssumeUserSelectionChanged = async (index) => {
    // index value will be undefined when selection is cleared
    const assumedUser = index != undefined ? thePortalUsers[index.value] : null;

    if ((assumedUser && !user.assumedUser) || (!assumedUser && user.assumedUser) || (assumedUser.entityId != user.assumedUser?.entityId)) {
      mutateUser(await fetchJson("/api/auth/assumedUser", { body: JSON.stringify(assumedUser), method: "POST" }));
      console.log(assumedUser, "mutated user");
    }

    // unfocus the element after changed event
    document.activeElement.blur();
  }

  const onAccountChange = async (companyId) => {
    let isSet = user.activeCustomer;

    mutateUser(await fetchJson("/api/accountswitch", { body: JSON.stringify({ companyId }), method: "POST" }));
    if (isSet) {
      await fetchJson("/api/accesslogs/" + companyId, {
        method: "POST",
        body: JSON.stringify({
          name: userlib.getUserName(user),
          email: userlib.getUserEmail(user),
          role: userlib.getAccessRole(user),
        }),
      });
      router.push("/dashboard");
      mutateCases([]);
      mutateInventory([]);
      mutateLocations([]);
      mutateContacts([]);
    }
  };
  
  const items = [
    {
      key: 'manunav-1',
      label: (
        <a target="_blank" rel="noopener noreferrer"  onClick={logout}>
          <LogoutOutlined style={{marginRight: "10px"}} />
          Logout
        </a>
      ),
    },
    // {
    //   key: '2',
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
    //       2nd menu item (disabled)
    //     </a>
    //   ),
    //   icon: <SmileOutlined />,
    //   disabled: true,
    // },
    // {
    //   key: '3',
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
    //       3rd menu item (disabled)
    //     </a>
    //   ),
    //   disabled: true,
    // },
    // {
    //   key: '4',
    //   danger: true,
    //   label: 'a danger item',
    // },
  ];
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        zeroWidthTriggerStyle={{ top: 0 }}
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div style={{ height: 60, width: "100%", padding: 5, position: "relative" }}>
          <Link href="/dashboard">
            <a>
              <Image
                src="/ec_logo.png"
                height="40"
                width="120"
                layout="responsive"
                objectFit="contain"
                alt="Element Critical Logo"
              />
            </a>
          </Link>
        </div>
        <Menu
          theme="dark"
          selectedKeys={[router.pathname]}
          mode="inline"
          defaultOpenKeys={[...permissionLinks.filter((item) => item.subLinks).map((item) => item.href)]}
        >
          {permissionLinks.map((menu, index) =>
            menu.subLinks ? (
              <SubMenu key={menu.href} title={menu.title} icon={menu.icon}>
                {menu.subLinks.map((sublink) => (
                  <Menu.Item onClick={() => router.push(sublink.href)} key={sublink.href}>
                    {sublink.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item onClick={() => router.push(menu.href)} key={menu.href} icon={menu.icon}>
                {/* {console.log(menu, "menu")} */}
                {menu.title}
              </Menu.Item>
            )
          )}
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => logout()}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Loading />
        <Header>
          <Row justify="space-between" align="middle" style={{ height: "100%" }} wrap={false}>
            {/* button to collapse/open side main nav */}
            <Button
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed((prev) => !prev)}
              type="primary"
              style={{ position: "relative", left: -32 }}
            />
            {/* Element Critical Employee only Assume a User */}
            {canLoadPortalUsers ? (
              <Select
                labelInValue={true}
                value={user?.assumedUser ? {value: user.assumedUser.entityId, label: <span><b>{user.assumedUser.userName}</b> - <i>{user.assumedUser.customers[0].name}</i></span>} : null}
                bordered={false}
                style={{ width: "100%", maxWidth: 300, borderRadius: 30, backgroundColor: "white", color: "black"}}
                showSearch
                loading={!thePortalUsers}
                disabled={!thePortalUsers}
                onChange={onAssumeUserSelectionChanged}
                placeholder="Assume an identity..."
                allowClear={true}
                filterOption={(input, option) => {
                  return (
                    option.title.toLowerCase().indexOf(input.toLowerCase(0)) >= 0
                  )
                }}
              >
              {thePortalUsers?.map((singleUser, index) => (
                  <Select.Option key={singleUser.entityId} value={index} title={singleUser.userName + " " + singleUser.customers[0].name}>
                    <b>{singleUser.userName}</b> - <i>{singleUser.customers[0].name}</i>
                  </Select.Option>
                ))}
              </Select>
            ) : null}

            {/* Antd top right menu nav */}
            <Dropdown
              menu={{
                items
              }}
              // trigger={["hover"]}
              // open="false" //set this to false to open the dropdown
              overlayStyle={{ width: 130 }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {<UserOutlined style={{ color: "#82BA3F", fontSize: "20px", display: "inline" }} />}
                    <span className="nunito-sans" style={{ color: "white", }}>{user?.userName}</span>
                  <DownOutlined style={{ color: "gray" }} />
                </Space>
              </a>
            </Dropdown>
          </Row>
        </Header>
        <Content style={{ margin: "0 15px", paddingTop: "15px" }}>{props.children}</Content>
        <Footer style={{ textAlign: "center" }}>Element Critical</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
