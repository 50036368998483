import { kv } from "@vercel/kv";

const masterAdminRole = "Master Admin";
const locationAdminRole = "Location Admin";
const userRole = "User";

const userlib = {};

userlib.getUser = async function(req) {
    const sessionUser = req.session.get('user');
    if (!sessionUser || !sessionUser.entityId) return null;
    return await kv.get(sessionUser.entityId);
}

userlib.setUser = async function(user) {
    await kv.set(user.entityId, user);
}

userlib.getUserId = function(user) {
    return user?.assumedUser ? user?.assumedUser.entityId : user?.entityId;
}

userlib.getUserName = function(user) {
    return user?.assumedUser ? user?.assumedUser.userName : user?.userName;
}

userlib.getUserEmail = function(user) {
    return user?.assumedUser ? user?.assumedUser.email : user?.email;
}

userlib.getActiveCompanyName = function(user) {
    return user?.assumedUser 
    ? user?.assumedUser.customers.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.name 
    : user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.name;
}

userlib.getActiveCompanyId = function(user) {
    return user?.assumedUser ? user?.assumedUser.activeCustomer : user?.activeCustomer;
}

userlib.setActiveCompanyId = async function(user, companyId) {
    user?.assumedUser 
    ? user.assumedUser.activeCustomer = companyId
    : user.activeCustomer = companyId;
    await userlib.setUser(user);
    return user;
}

userlib.getAllCompanyIds = function(user) {
    return user?.assumedUser 
    ? [user?.assumedUser.activeCustomer, ...user?.assumedUser.customers?.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.locations.map((item) => item?.childCompanyId)]
    : [user?.activeCustomer, ...user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.locations.map((item) => item?.childCompanyId)];
}

userlib.getAllCustomers = function(user) {
    return user?.assumedUser ? user?.assumedUser.customers : user?.customers;
}

userlib.getAllLocations = function(user) {
    return user?.assumedUser 
    ? user?.assumedUser?.customers?.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.locations
    : user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.locations;
}

userlib.getAllLocationIds = function(user) {
    return user?.assumedUser 
    ? user?.assumedUser.customers?.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.locations.map((loc) => { return { internalid: loc.location, childCompanyId: loc.childCompanyId }; })
    : user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.locations.map((loc) => { return { internalid: loc.location, childCompanyId: loc.childCompanyId }; });
}

userlib.getLocationById = function(user, id) {
    return user?.assumedUser
    ? user?.assumedUser.customers?.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.locations.find((loc) => loc.location == id)
    : user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.locations.find((loc) => loc.location == id);
}

userlib.getAccessRole = function(user) {
    if (user?.assumedUser) {
        return user?.assumedUser.customers?.find((customer) => customer.id == user?.assumedUser.activeCustomer)?.isMasterAdmin
        ? "Master Admin" 
        : user?.assumedUser.customers
            ?.find((customer) => customer.id == user?.assumedUser.activeCustomer)
            ?.locations?.some((item) => item.isLocationAdmin) 
        ? "Location Admin"
        : "User";
    }
    else {
        return user?.customers?.find((customer) => customer.id == user?.activeCustomer)?.isMasterAdmin
        ? "Master Admin"
        : user?.customers
            ?.find((customer) => customer.id == user?.activeCustomer)
            ?.locations?.some((item) => item.isLocationAdmin)
        ? "Location Admin"
        : "User";
    }
}

userlib.isMasterAdmin = function(user) {
    return userlib.getAccessRole(user) == masterAdminRole;
}

userlib.isLocationAdmin = function(user) {
    return userlib.getAccessRole(user) == locationAdminRole;
}

userlib.canLoadPortalUsers = function(user) {
    return process.env.NEXT_PUBLIC_EC_COMPANY_IDS.split(',').includes(user?.activeCustomer) && 
    user?.customers?.find((item) => item?.id?.toString() == user?.activeCustomer?.toString())?.isMasterAdmin;
}

export default userlib;