import axios from "axios";

const netsuiteLib = {};

netsuiteLib.get = async function(scriptId, deploymentId, data) {
    return await restletRequest("get", scriptId, deploymentId, data);
}

netsuiteLib.put = async function(scriptId, deploymentId, data) {
    return await restletRequest("put", scriptId, deploymentId, data);
}

netsuiteLib.post = async function(scriptId, deploymentId, data) {
    return await restletRequest("post", scriptId, deploymentId, data);
}

netsuiteLib.delete = async function(scriptId, deploymentId, data) {
    return await restletRequest("delete", scriptId, deploymentId, data);
}

const restletRequest = async function(method, scriptId, deploymentId, data, start, end) {

    method = method.toLowerCase();

    start = start ? start : 0;
    end = end ? end : Number(process.env.NEXT_PUBLIC_MAX_NETSUITE_RECORDS);

    if (start < 0 || end < 1 || start >= end) {
        console.error("Start range value must be greater than or equal to 0 and less than End range value.");
        return;
    }

    const max = end - start;
    const increment = max + 1;

    // Verify required parameters
    if (!scriptId) {
        console.error("NetSuite scriptId is required.");
        return;
    }
    if (!deploymentId) {
        console.error("NetSuite script deploymentId is required.");
        return;
    }
    if (data && (method == 'get' || method == 'delete')) {
        if (typeof data != 'object' || data.constructor.name == "Array") {
            console.error("HTTP method type '" + method + "' parameters must be object key/value pairs.");
            return;
        }
    }
    if (!data && (method == 'post' || method == 'put')) {
        console.error("Data required for HTTP method type: '" + method + "'");
        return;
    }

    let responseData = await axios.post('/api/netsuite', {method: method, scriptId: scriptId, deploymentId: deploymentId, data: data, start: start, end: end})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    if (responseData.success && responseData.results.length == max) {
        start += increment;
        end += increment;
        
        const moreData = await restletRequest(method, scriptId, deploymentId, data, start, end);

        if (moreData.success) {
            responseData.results.push(...moreData.results);
        }
    }

    return responseData;
}

export default netsuiteLib;