import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import userlib from "@/lib/userlib";
import s from "./PDFViewer.module.scss";
import useUser from "@/lib/useUser";

const PDFViewer = () => {
  const [watermarkedPDFs, setWatermarkedPDFs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Create loading state
  const { user } = useUser();

  useEffect(() => {
    setWatermarkedPDFs([]); // Clear watermarked PDFs when user changes 
  }, [user?.assumedUser]);

  async function handleAddWatermark(inputPDFPath, pdfName) {
    const watermarkText = userlib.getActiveCompanyName(user);
    setIsLoading(true); // Set loading state to true when request is made
    try {
      const response = await fetch("/api/addwatermark", {
        method: "POST",
        body: JSON.stringify({ inputPDFPath, watermarkText }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        const { watermarkedPDFURL } = data;
        setWatermarkedPDFs((watermarkedPDFs) => [...watermarkedPDFs, { url: watermarkedPDFURL, name: pdfName }]);
      } else {
        console.error("Error adding watermark:", response.status);
      }
    } catch (error) {
      console.error("Error adding watermark:", error);
    }
    setIsLoading(false); // Set loading state to false when response is received
  }

  // bridge letter function - need to create a new PDF from a template literal string to create a bridge letter. We want the dates to be dynamic based on the date this function is called.
  async function bridgeLetter(){
    setIsLoading(true); // Set loading state to true when request is made
    try {
      const response = await fetch("/api/addwatermark", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ createLetter: true }),
      });
      if (response.ok) {
        const data = await response.json();
        const { bridgeLetterPDFURL } = data;
        setWatermarkedPDFs((watermarkedPDFs) => [...watermarkedPDFs, { url: bridgeLetterPDFURL, name: "Element Critical 2023 - Bridge Letter" }]);
      } else {
        console.error("Error creating bridge letter:", response.status);
      }
    } catch (error) {
      console.error("Error creating bridge letter:", error);
    }
    setIsLoading(false); // Set loading state to false when response is received
  }

  return (
    <>
      <div>
        <div className="ant-table-wrapper">
          <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
              <div className="ant-table">
                <div className="ant-table-container">
                  <div className="ant-table-content">
                    <table style={{ tableLayout: "auto" }}>
                      <colgroup />
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">File Name</th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                      
                        {/* ISO 27001 Certification */}
                        <tr
                          data-row-key="ISO27001ConfirmationOfEngagement"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - ISO 27001 Recertification - Final Report</p>
                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>
                                  handleAddWatermark(
                                    "https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-ISO27001-Recertification-Final-Report.pdf",
                                    "Element Critical 2023 - ISO 27001 Recertification - Final Report"
                                  )
                                }
                              >
                                Watermark This PDF
                              </button>
                            </div>
                            
                          </td>
                        </tr>

                        {/* PSI DSS AOC */}
                        <tr
                          data-row-key="PCIDSSAOC"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - PCI DSS AOC - Final Report</p>

                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>
                                  handleAddWatermark(
                                    "https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-PCI-DSS-AOC-FinalReport.pdf",
                                    "Element Critical 2023 - PCI DSS AOC - Final Report"
                                  )
                                }
                              >
                                Watermark This PDF
                              </button>
                            </div>
                            
                          </td>
                        </tr>

                        {/* Type 1 HIPAA FINAL REPORT */}
                        <tr
                          data-row-key="Type1HIPAAFinalReport"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - Type 1 HIPAA - Final Report</p>

                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>
                                  handleAddWatermark(
                                    "https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type1-HIPAA-FinalReport.pdf",
                                    "Element Critical 2023 - Type 1 HIPAA - Final Report"
                                  )
                                }
                              >
                                Watermark This PDF
                              </button>
                            </div>
                          </td>
                        </tr>

                        {/* Type 2 SOC 1 & ISAE 3402 FINAL REPORT */}
                        <tr
                          data-row-key="Type2SOC1ISAE3402"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - Type 2 SOC 1 &amp; ISAE 3402 - Final Report</p>

                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>
                                  handleAddWatermark(
                                    "https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type2-SOC1-ISAE-3402-Final-Report.pdf",
                                    "Element Critical 2023 - Type 2 SOC 1 & ISAE 3402 - Final Report"
                                  )
                                }
                              >
                                Watermark This PDF
                              </button>
                            </div>
                            
                          </td>
                        </tr>

                        {/* Type 2 SOC 2 FINAL REPORT */}
                        <tr
                          data-row-key="Type2SOC2"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - Type 2 SOC 2 - Final Report</p>
                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>
                                  handleAddWatermark(
                                    "https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type2-SOC-2-Final-Report.pdf",
                                    "Element Critical 2023 - Type 2 SOC 2 - Final Report"
                                  )
                                }
                              >
                                Watermark This PDF
                              </button>
                            </div>
                            
                          </td>
                        </tr>
                        
                        {/* Bridge Letter */}
                        <tr
                          data-row-key="BridgeLetter"
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="ant-table-cell">
                            <p className={s.pdfname}>Element Critical 2023 - Bridge Letter</p>

                            <div className={s.watermarkBlock}>
                              <button
                                className={s.watermarkBtn}
                                onClick={() =>bridgeLetter()}
                              >
                                Download Bridge Letter
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Watermark Output UI */}
      {watermarkedPDFs.length > 0 && (
        <div id="wm-download-div">
          <h1 style={{paddingTop:"15px"}}>Download PDF Files</h1>
          {console.log("Watermarked PDFs", watermarkedPDFs)}
          {watermarkedPDFs.map((pdf, index) => (
            <div key={index}>
              <a href={pdf.url} download={pdf.name} style={{fontSize: "16px"}}>
                {pdf.name}
              </a>
            </div>
          ))}
        </div>
      )}
      {/* Loading icon */}
      {isLoading && <Spin size="medium" style={{paddingTop: "10px"}}/>}
    </>
  );
};
export default PDFViewer;
